<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('common2[0]')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <div style="width: 95%;margin-left: 10px"  v-for="item in servicelist">
        <van-cell
                   size="large"
                   :icon="`./static/icon/fund.png`"
                   :title="item.title"
                   @click="$router.push(`/article/service/${item.id}`)"
                   center
                   is-link
        />
      </div>
      <div style="line-height: 45px;color: #cccccc;margin: 10px auto;text-align: center">
        <div>{{$t('common7[0]')}}</div>
      <van-button color="#F5C73A" size="large" style="margin: 10px auto;width: 25%;text-align: center"
                  to="/serviceCenter"
      ><span style="color:#000">{{$t('common7[1]')}}</span>
      </van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Service',
  components: {

  },
  props: {},
  data() {
    return {
      servicelist:[]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    
  },
  mounted() {
    this.servicelist = this.InitData.serviceList;
  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
 
  }
}
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
    font-weight: bold;
  }
  .ScrollBox{
    background-color: #13171A;
  }

  .van-cell {
    padding: 17px 0;
    background-color: #191C23;
  }

  .van-cell__title {
    color: #e7e7e7;
  }

</style>